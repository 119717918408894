
import getPageQuery from '~/queries/getPageQuery';

export default {
  name: 'PageEngine',
  // Note: fetch is called on server-side when rendering the route,
  // and on client-side when navigating.
  async asyncData({ store, error, route, i18n, $sanity }) {
    const { locale } = i18n;
    const { isPreviewMode, documentData } = store.state.preview;
    const {
      params: { pathMatch }
    } = route;

    // this.page will be hydrated with draft data taken from the store
    // and fetched in the middleware 'previewMode'
    if (isPreviewMode) {
      return { page: documentData };
    }

    try {
      const pageQuery = getPageQuery(pathMatch, locale);
      const page = await $sanity.fetch(pageQuery);
      const isPageEmpty = page == null || Object.keys(page).length === 0;

      if (isPageEmpty) {
        error({
          statusCode: 404,
          message: 'Page not found'
        });
      }

      store.commit('page/setTranslations', page.translations);
      store.commit('ui/setPageLayout', page._type);

      return {
        page
      };
    } catch (err) {
      if (process.server) {
        error({
          statusCode: 404,
          message: 'Page not found'
        });
      }
    }
  },
  data() {
    return {
      page: null
    };
  },
  head() {
    // generate meta tags only when not in preview
    const { isPreviewMode } = this.$store.state.preview;
    return !isPreviewMode && this.generatePageMetaTags(this.page);
  },

  mounted() {
    this.initLocalStorage();
  }
};
